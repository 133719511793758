var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex flex-row",
          staticStyle: { "background-color": "#f3f4f5" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column justify-center align-items-center w-18",
            },
            [
              _c("div", { staticClass: "content-c1 gap-10" }, [
                _c(
                  "div",
                  [
                    _c("MonthSelect", {
                      attrs: { DateDefaultSelect: _vm.setDefaultDate },
                      on: { setDateDefaultSelect: _vm.setDateDefaultSelect },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "outline-primary" },
                        on: { click: _vm.handleClickReference },
                      },
                      [
                        _c("span", { staticClass: "text-btn" }, [
                          _vm._v(_vm._s(_vm.FormMSG(9, "Preference"))),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-wrap mr-4 ml-4 active" },
                  [
                    _c("treeselect", {
                      attrs: {
                        options: _vm.mapCode,
                        value: _vm.selected_code,
                        "disable-branch-nodes": true,
                        clearable: _vm.clearable,
                      },
                      on: {
                        input: _vm.handleChangeCode,
                        select: _vm.codeChanged,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "value-label",
                          fn: function ({ node }) {
                            return _c("div", { attrs: { title: node.label } }, [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.setTextCategoriesWithActivities(
                                      node.label
                                    )
                                  )
                                ),
                              ]),
                            ])
                          },
                        },
                        {
                          key: "option-label",
                          fn: function ({ node }) {
                            return _c("div", {}, [
                              _c(
                                "div",
                                {
                                  staticClass: "treeselect-label",
                                  attrs: { title: node.label },
                                },
                                [_vm._v(_vm._s(node.label))]
                              ),
                            ])
                          },
                        },
                      ]),
                      model: {
                        value: _vm.selected_code,
                        callback: function ($$v) {
                          _vm.selected_code = $$v
                        },
                        expression: "selected_code",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "divider-vertical w-82" }, [
            _c(
              "div",
              { staticClass: "d-flex align-items-start p-2 flex-column w-100" },
              [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-row w-100",
                    staticStyle: { height: "50px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-wrap flex-row w-80 p-2" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row flex-wrap p-2 w-75 active",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "w-85" },
                              [
                                _c("treeselect", {
                                  attrs: {
                                    options: _vm.mapCategories,
                                    value: _vm.selected_categorie,
                                    "disable-branch-nodes": true,
                                    placeholder: _vm.FormMSG(
                                      2,
                                      "Select an categories"
                                    ),
                                    "default-expand-level": 1,
                                  },
                                  on: {
                                    input: _vm.handleChangeCategories,
                                    select: _vm.categoriesChanged,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "value-label",
                                      fn: function ({ node }) {
                                        return _c(
                                          "div",
                                          { attrs: { title: node.label } },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.setTextCategoriesWithActivities(
                                                    node.label
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        )
                                      },
                                    },
                                    {
                                      key: "option-label",
                                      fn: function ({ node }) {
                                        return _c("div", {}, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "treeselect-label",
                                              attrs: { title: node.label },
                                            },
                                            [_vm._v(_vm._s(node.label))]
                                          ),
                                        ])
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.selected_categorie,
                                    callback: function ($$v) {
                                      _vm.selected_categorie = $$v
                                    },
                                    expression: "selected_categorie",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-wrap mt-0 w-15 active",
                              },
                              [
                                _vm.showAllMapCategorie
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn-category",
                                        on: {
                                          click: _vm.handleShowMadCategories,
                                        },
                                      },
                                      [
                                        !_vm.isLargeDeskTop
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "item-text w-trimestre",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      35,
                                                      "By trimestre"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover.top:title",
                                                    value: _vm.FormMSG(
                                                      35,
                                                      "By trimestre"
                                                    ),
                                                    expression:
                                                      "FormMSG(35, 'By trimestre')",
                                                    modifiers: {
                                                      hover: true,
                                                      "top:title": true,
                                                    },
                                                  },
                                                ],
                                              },
                                              [
                                                _c(
                                                  _vm.getLucideIcon(
                                                    _vm.ICONS.CLOCK.name
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#FFFFFF",
                                                      size: 20,
                                                    },
                                                  }
                                                ),
                                              ],
                                              1
                                            ),
                                      ]
                                    )
                                  : _c(
                                      "button",
                                      {
                                        staticClass: "btn-category",
                                        on: {
                                          click: _vm.handleShowMadCategories,
                                        },
                                      },
                                      [
                                        !_vm.isLargeDeskTop
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "item-text w-show-all",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(34, "Show all")
                                                  )
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover.top:title",
                                                    value: _vm.FormMSG(
                                                      34,
                                                      "Show all"
                                                    ),
                                                    expression:
                                                      "FormMSG(34, 'Show all')",
                                                    modifiers: {
                                                      hover: true,
                                                      "top:title": true,
                                                    },
                                                  },
                                                ],
                                              },
                                              [
                                                _c(
                                                  _vm.getLucideIcon(
                                                    _vm.ICONS.VIEW.name
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: {
                                                      color: "#FFFFFF",
                                                      size: 20,
                                                    },
                                                  }
                                                ),
                                              ],
                                              1
                                            ),
                                      ]
                                    ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-wrap p-2 w-25 active" },
                          [
                            _c("treeselect", {
                              attrs: {
                                options: _vm.mapActivities,
                                value: _vm.selected_activity,
                                placeholder: _vm.FormMSG(
                                  3,
                                  "Select an activities"
                                ),
                              },
                              on: {
                                input: _vm.handleChangeActivities,
                                select: _vm.activitiesChanged,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "option-label",
                                  fn: function ({ node }) {
                                    return _c("div", {}, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "treeselect-label",
                                          attrs: { title: node.label },
                                        },
                                        [_vm._v(_vm._s(node.label))]
                                      ),
                                    ])
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-start w-20 p-2" },
                      [
                        _c(
                          "div",
                          {
                            ref: "headerFlagItem",
                            staticClass:
                              "d-flex justify-content-end mt-1 w-100",
                          },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.bottom.html",
                                    value: `${
                                      _vm.isLargeDeskTop
                                        ? _vm.getTextLabel() +
                                          " " +
                                          _vm.countItemToDeletes(
                                            _vm.dataPlanningSelect
                                          )
                                        : ""
                                    }`,
                                    expression:
                                      "`${isLargeDeskTop ? getTextLabel() + ' ' + countItemToDeletes(dataPlanningSelect) : ''}`",
                                    modifiers: {
                                      hover: true,
                                      bottom: true,
                                      html: true,
                                    },
                                  },
                                ],
                                staticClass: "btn-custom mr-2",
                                style: _vm.defaultStyle(false),
                                attrs: {
                                  disabled: _vm.isRazSelection,
                                  variant: "danger",
                                  size: "sm",
                                },
                                on: { click: _vm.onOpenModalToAllDelete },
                              },
                              [
                                _c(_vm.getLucideIcon(_vm.ICONS.TRASH.name), {
                                  tag: "component",
                                  attrs: {
                                    id: "lock-planning-info",
                                    color: "#FFFFFF",
                                    size: 20,
                                  },
                                }),
                                !_vm.isLargeDeskTop
                                  ? _c("span", { staticClass: "text-btn" }, [
                                      _vm._v(
                                        _vm._s(_vm.getTextLabel()) +
                                          " " +
                                          _vm._s(
                                            _vm.countItemToDeletes(
                                              _vm.dataPlanningSelect
                                            )
                                          )
                                      ),
                                    ])
                                  : _vm._e(),
                                !_vm.isLargeDeskTop && _vm.containsZeroAndOne
                                  ? _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName:
                                              "v-b-tooltip.hover.bottom.html",
                                            value: `${_vm.FormMSG(
                                              55,
                                              "The elements that you have selected contain elements that have been sent to pay, if you delete it, certain element must go to ask for delete"
                                            )}`,
                                            expression:
                                              "\n\t\t\t\t\t\t\t\t\t\t`${FormMSG(\n\t\t\t\t\t\t\t\t\t\t\t55,\n\t\t\t\t\t\t\t\t\t\t\t'The elements that you have selected contain elements that have been sent to pay, if you delete it, certain element must go to ask for delete'\n\t\t\t\t\t\t\t\t\t\t)}`\n\t\t\t\t\t\t\t\t\t",
                                            modifiers: {
                                              hover: true,
                                              bottom: true,
                                              html: true,
                                            },
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          _vm.getLucideIcon(
                                            _vm.ICONS.INFO.name
                                          ),
                                          {
                                            tag: "component",
                                            attrs: {
                                              id: "contains-zero-and-one",
                                              color: "#FFFFFF",
                                              size: 20,
                                            },
                                          }
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticClass: "d-flex flex-row w-100" }, [
                  !_vm.isGestionnaryOfPaie
                    ? _c(
                        "div",
                        { staticClass: "d-flex flex-wrap flex-row w-80 p-2" },
                        [
                          _vm.flags_options_favoris.length === 0
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-center align-items-center w-100 text-muted fs-14 p-2",
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            26,
                                            "No favorite flags selected"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._l(
                                _vm.flags_options_favoris,
                                function (option, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass:
                                        "d-flex flex-wrap p-2 gap-10",
                                      attrs: { id: "flagItem" },
                                    },
                                    [
                                      _c("flag-items", {
                                        attrs: { option: option },
                                        on: { setFlags: _vm.setFlags },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-start w-20 p-2" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-content-end mt-1 w-100",
                        },
                        [
                          !_vm.isGestionnaryOfPaie
                            ? _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.bottom.html",
                                      value: `${
                                        _vm.isLargeDeskTop
                                          ? _vm.FormMSG(5, "Add to selection")
                                          : ""
                                      }`,
                                      expression:
                                        "`${isLargeDeskTop ? FormMSG(5, 'Add to selection') : ''}`",
                                      modifiers: {
                                        hover: true,
                                        bottom: true,
                                        html: true,
                                      },
                                    },
                                  ],
                                  staticClass: "btn-custom mr-2",
                                  style: _vm.defaultStyle(false),
                                  attrs: {
                                    disabled: _vm.checkUserAuthorization,
                                    variant: "primary",
                                    size: "sm",
                                  },
                                  on: { click: _vm.handleClickAddSelection },
                                },
                                [
                                  _vm._t("button-label", function () {
                                    return [
                                      _c(
                                        _vm.getLucideIcon(
                                          _vm.ICONS.PLUS_CIRCLE_II.name
                                        ),
                                        {
                                          tag: "component",
                                          attrs: {
                                            color:
                                              _vm.ICONS.PLUS_CIRCLE_II.color,
                                            size: 20,
                                          },
                                        }
                                      ),
                                      !_vm.isLargeDeskTop
                                        ? _c(
                                            "span",
                                            { staticClass: "text-btn" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    5,
                                                    "Add to selection"
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "d-flex flex-row w-100 mb-1" }, [
                  _c("div", { staticClass: "d-flex flex-row w-100" }, [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex flex-row align-items-center w-80",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex flex-wrap w-50 ml-3 active" },
                          [
                            !_vm.isGestionnaryOfPaie
                              ? _c("treeselect", {
                                  attrs: {
                                    options: _vm.mapOtherRubrique,
                                    value: _vm.selected_no_favoris,
                                    placeholder: _vm.FormMSG(
                                      6,
                                      "Select other rubrique"
                                    ),
                                    disabled: _vm.mapOtherRubrique.length === 1,
                                    multiple: true,
                                  },
                                  on: { input: _vm.handleSelectedNoFavoris },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "value-label",
                                        fn: function ({ node }) {
                                          return _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.hover.bottom.html",
                                                  value: `${_vm.setTextGlobal(
                                                    node.label
                                                  )}`,
                                                  expression:
                                                    "`${setTextGlobal(node.label)}`",
                                                  modifiers: {
                                                    hover: true,
                                                    bottom: true,
                                                    html: true,
                                                  },
                                                },
                                              ],
                                              staticClass:
                                                "d-flex align-items-center justify-content-center",
                                              staticStyle: {
                                                height: "12px !important",
                                              },
                                              attrs: {
                                                title: _vm.setTextValue(
                                                  node.label
                                                ),
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size":
                                                      "10px !important",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.setTextValue(
                                                        node.label
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        },
                                      },
                                      {
                                        key: "option-label",
                                        fn: function ({ node }) {
                                          return _c("div", {}, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "treeselect-label",
                                                attrs: {
                                                  title: _vm.setText(
                                                    node.label
                                                  ),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.setText(node.label)
                                                  )
                                                ),
                                              ]
                                            ),
                                          ])
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2358086323
                                  ),
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row align-items-start position-relative w-20",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row justify-content-end ml-1 p-2 w-100",
                          },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.bottom.html",
                                    value: `${
                                      _vm.isLargeDeskTop
                                        ? _vm.isProduction
                                          ? _vm.FormMSG(
                                              27,
                                              "Validate all planning"
                                            )
                                          : _vm.FormMSG(28, "Send all planning")
                                        : ""
                                    }`,
                                    expression:
                                      "\n\t\t\t\t\t\t\t\t\t\t`${isLargeDeskTop ? (isProduction ? FormMSG(27, 'Validate all planning') : FormMSG(28, 'Send all planning')) : ''}`\n\t\t\t\t\t\t\t\t\t",
                                    modifiers: {
                                      hover: true,
                                      bottom: true,
                                      html: true,
                                    },
                                  },
                                ],
                                staticClass: "btn-custom mr-2",
                                style: _vm.defaultStyle(_vm.lockPlanning),
                                attrs: {
                                  variant: "success",
                                  disabled: _vm.lockPlanning,
                                  size: "sm",
                                  id: "menu-to-validate-all",
                                  tabindex: "0",
                                },
                              },
                              [
                                _vm._t(
                                  "button-label-validate-all",
                                  function () {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center justify-content-center w-100",
                                          style: {
                                            width: !_vm.isLargeDeskTop
                                              ? `calc(${
                                                  _vm.defaultStyle(
                                                    _vm.lockPlanning
                                                  ).width
                                                } - 10px) !important`
                                              : "",
                                            maxWidth: !_vm.isLargeDeskTop
                                              ? "200px !important"
                                              : "",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                "d-flex align-items-center justify-content-center": true,
                                                "w-30 ml--30px":
                                                  !_vm.isLargeDeskTop &&
                                                  !_vm.lockPlanning,
                                                "w-32 ml--10px":
                                                  !_vm.isLargeDeskTop &&
                                                  _vm.lockPlanning,
                                                "w-100": _vm.isLargeDeskTop,
                                              },
                                            },
                                            [
                                              _c(
                                                _vm.getLucideIcon(
                                                  _vm.ICONS.CHECK_SQUARE.name
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color: "#FFFFFF",
                                                    size: 20,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          ),
                                          !_vm.isLargeDeskTop
                                            ? _c(
                                                "div",
                                                {
                                                  class: {
                                                    "d-flex align-items-center justify-content-start": true,
                                                    "w-70": !_vm.lockPlanning,
                                                    "w-66": _vm.lockPlanning,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row w-100",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex align-items-center justify-content-center w-75",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.isProduction
                                                                  ? _vm.FormMSG(
                                                                      27,
                                                                      "Validate all planning"
                                                                    )
                                                                  : _vm.FormMSG(
                                                                      28,
                                                                      "Send all planning"
                                                                    )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex align-items-center justify-content-center w-25",
                                                        },
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS
                                                                .CHEVRON_DOWN
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  "#FFFFFF",
                                                                size: 20,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  }
                                ),
                                _vm.lockPlanning
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "d-flex position-absolute",
                                      },
                                      [
                                        _c(_vm.getLucideIcon("Lock"), {
                                          tag: "component",
                                          attrs: {
                                            color: "#000000",
                                            size: 25,
                                            "stroke-width": 1.5,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm.lockPlanning
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName:
                                          "v-b-tooltip.hover.bottom.html",
                                        value: _vm.isGestionnaryOfPaie
                                          ? _vm.FormMSG(
                                              201,
                                              "Planning is locked, you can unlocked that"
                                            )
                                          : _vm.FormMSG(
                                              200,
                                              "Planning is locked"
                                            ),
                                        expression:
                                          "\n\t\t\t\t\t\t\t\t\t\tisGestionnaryOfPaie ? FormMSG(201, 'Planning is locked, you can unlocked that') : FormMSG(200, 'Planning is locked')\n\t\t\t\t\t\t\t\t\t",
                                        modifiers: {
                                          hover: true,
                                          bottom: true,
                                          html: true,
                                        },
                                      },
                                    ],
                                    staticClass:
                                      "d-flex align-items-center justify-content-center cursor-pointer prx-10",
                                  },
                                  [
                                    _vm.isGestionnaryOfPaie
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass: "custom-but-unlock",
                                            attrs: { variant: "light" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                $event.stopPropagation()
                                                return _vm.unLockedPlanning.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.getLucideIconServer()
                                                ),
                                              },
                                            }),
                                          ]
                                        )
                                      : _c(
                                          _vm.getLucideIcon(
                                            _vm.ICONS.INFO.name
                                          ),
                                          {
                                            tag: "component",
                                            attrs: {
                                              color: _vm.ICONS.INFO.color,
                                              size: 20,
                                            },
                                          }
                                        ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: "menu-to-validate-all",
                                  variant: "light",
                                  triggers: "focus",
                                  placement: "bottom",
                                  "custom-class": "custom-popover",
                                },
                              },
                              [
                                _vm.showGrouping.INITIAL
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "content-item-popover",
                                        attrs: {
                                          disabled: _vm.mapActive.INITIAL,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.validateAllPlanning(
                                              _vm.ACTION_PLANNING_FLUX.INITIAL
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "popover-item-icon" },
                                          [
                                            _c(
                                              _vm.getLucideIcon("CalendarDays"),
                                              {
                                                tag: "component",
                                                attrs: {
                                                  color: "#06263e",
                                                  size: 20,
                                                },
                                              }
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "popover-item-text text-1",
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(42, "Initial")
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.showProgress
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "content-item-popover",
                                        attrs: {
                                          disabled: _vm.mapActive.INPROGRESS,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.validateAllPlanning(
                                              _vm.ACTION_PLANNING_FLUX
                                                .INPROGRESS
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "popover-item-icon" },
                                          [
                                            _c(
                                              _vm.getLucideIcon(
                                                "CalendarSearch"
                                              ),
                                              {
                                                tag: "component",
                                                attrs: {
                                                  color: "#06263e",
                                                  size: 20,
                                                },
                                              }
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "popover-item-text text-1",
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(43, "In progress")
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "button",
                                  {
                                    staticClass: "content-item-popover",
                                    attrs: { disabled: _vm.mapActive.FINAL },
                                    on: {
                                      click: function ($event) {
                                        return _vm.validateAllPlanning(
                                          _vm.ACTION_PLANNING_FLUX.FINAL
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "popover-item-icon" },
                                      [
                                        _c(_vm.getLucideIcon("CalendarCheck"), {
                                          tag: "component",
                                          attrs: { color: "#06263e", size: 20 },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "popover-item-text text-1",
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(_vm.FormMSG(44, "Final")) +
                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm.mapPlanningStatus !== null
                  ? _c(
                      "div",
                      { staticClass: "d-flex flex-column w-100 mb-1" },
                      [
                        _c(
                          "div",
                          { staticClass: "ml-3 mr-3" },
                          [
                            _c(
                              "b-alert",
                              { attrs: { show: "", variant: "primary" } },
                              [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.mapPlanningStatus),
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            title: _vm.FormMSG(21, "Do you want to remove these timesheets"),
            "modal-class": "mui-animation",
            fade: false,
          },
          on: { hidden: _vm.handleCloseAllDeleteModal },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row justify-content-end w-100 gap-1 w-1",
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "border-1",
                              attrs: { variant: "outline-primary", size: "sm" },
                              on: { click: _vm.handleCloseAllDeleteModal },
                            },
                            [_vm._v(" " + _vm._s(_vm.FormMSG(22, "No")) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "border-1",
                              attrs: { variant: "outline-danger", size: "sm" },
                              on: { click: _vm.handleDeleteAllPlanning },
                            },
                            [_vm._v(" " + _vm._s(_vm.FormMSG(23, "Yes")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.openModalDeleteAll,
            callback: function ($$v) {
              _vm.openModalDeleteAll = $$v
            },
            expression: "openModalDeleteAll",
          },
        },
        [
          _vm.groupByData && _vm.groupByData.length !== 0
            ? _c(
                "div",
                { staticClass: "d-flex flex-column gap-1 conten-item" },
                _vm._l(_vm.groupByData, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass:
                        "d-flex flex-row align-items-center w-100 border-rd p-2",
                    },
                    [
                      _c("div", { staticClass: "w-50" }, [
                        _c("label", [_vm._v(_vm._s(item.fullName))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column border-lf w-50" },
                        _vm._l(item.data, function (children, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "text-1" },
                            [_vm._v(_vm._s(children.date))]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }