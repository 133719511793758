<template>
	<b-modal
		v-model="isOpen"
		:title="FormMSG(86, 'Replacement')"
		header-class="header-class-modal-doc-package"
		size="md"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		ok-variant="primary"
		:ok-disabled="loadingSubmit"
		:cancel-title="FormMSG(79, 'Cancel')"
		cancel-variant="outline-primary"
		:cancel-disabled="loadingSubmit"
		@cancel="emitEventClose"
		@ok.prevent="handleSubmit"
		modal-class="mui-animation"
		:fade="false"
	>
		<div class="form">
			<b-row>
				<b-col>
					<fieldset class="my-3 border-groove-blue-streak" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-blue-streak">
							{{ FormMSG(132, 'Person to replace') }}
						</legend>
						<div class="pt-3 pb-3">
							<b-row>
								<b-col>
									<div class="fs-14 fw-700">
										{{ getFullName() }} {{ formatedDate() }}
										<span v-if="getLocation().id > 0">{{ getLocation().text }}</span>
									</div>
								</b-col>
							</b-row>
						</div>
					</fieldset>
				</b-col>
			</b-row>
			<b-row class="mt-2 mb-2">
				<b-col>
					{{ FormMSG(133, 'Select the replacement person from the list. All data for the day will be copied to the same day for this person.') }}
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<v-select
						v-model="$v.userReplacedSelected.$model"
						:options="users"
						:placeholder="FormMSG(49, 'Select a person')"
						:clearable="false"
						label="fullName"
						:class="{ 'is-invalid': $v.userReplacedSelected.$invalid }"
					/>
					<div v-if="$v.userReplacedSelected.$invalid" class="invalid-feedback">
						{{ FormMSG(69, 'User is required') }}
					</div>
				</b-col>
			</b-row>
		</div>

		<template #modal-ok>
			<div><b-spinner v-if="loadingSubmit" small /> {{ FormMSG(78, 'Validate replacement') }}</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import _ from 'lodash';
import { substituteUserTsDay } from '@/cruds/timesheets-efc.crud';
import { getProjectUsersTsDaysPlanning } from '@/cruds/timesheets-efc.crud';
import globalMixin from '@/mixins/global.mixin';
import moment from 'moment';

export default {
	name: 'Substitution',

	mixins: [languageMessages, globalMixin, validationMixin],

	props: {
		open: { type: Boolean, default: false, required: false },
		externalData: { type: Object, default: () => {}, required: false },
		customFilterValue: {
			type: Number,
			default: 0,
			required: false
		}
	},

	data() {
		return {
			loadingSubmit: false,
			users: [],
			userToReplace: null,
			userReplacedSelected: null
		};
	},

	validations: {
		userReplacedSelected: { required }
	},

	watch: {
		externalData: {
			handler(newVal) {
				if (newVal) {
					this.userToReplace = newVal;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		isOpen: {
			handler(newVal) {
				if (newVal) {
					this.getUsers();
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		}
	},

	methods: {
		async getUsers() {
			const typeFilter = {
				0: 'default',
				1: 'byName',
				2: 'byFunction',
				3: 'byCustom'
			};
			this.users = [...(await getProjectUsersTsDaysPlanning())]
				.filter((item) => +item.id !== +this.userToReplace.userId)
				.sort((a, b) => {
					if (typeFilter[this.customFilterValue] === 'byName') {
						return a.fullName.localeCompare(b.fullName);
					} else if (typeFilter[this.customFilterValue] === 'byFunction') {
						return a.functionName.localeCompare(b.functionName);
					}
					return b.fullName.localeCompare(a.fullName);
				});
		},
		getFullName() {
			const userToReplace = this.userToReplace;
			if (userToReplace) {
				const { fullName } = userToReplace;
				return `${fullName} - `;
			}
		},
		formatedDate() {
			const userToReplace = this.userToReplace;
			const langCurrent = this.currentLang;
			if (userToReplace) {
				const { tsDay } = userToReplace;
				const { date: defaultDate } = tsDay;
				return moment(defaultDate).utc().locale(`${langCurrent}`).format('dddd DD MMMM YYYY');
			}
			return moment().utc().locale(`${langCurrent}`).format('dddd DD MMMM YYYY');
		},
		getLocation() {
			const userToReplace = this.userToReplace;
			if (userToReplace) {
				const { locations } = userToReplace;
				const { id: locationID, setName: locationName } = locations;
				return {
					id: +locationID,
					text: `(${locationName})`
				};
			} else {
				return {
					id: 0,
					text: 'Off'
				};
			}
		},
		async handleSubmit() {
			this.$v.$touch();
			this.loadingSubmit = true;

			if (!this.$v.$invalid) {
				const { tsDay } = this.externalData;
				const { id: tsDayID } = tsDay;
				return await substituteUserTsDay(+tsDayID, +this.userReplacedSelected.id).then((responses) => {
					if (responses) {
						this.loadingSubmit = false;
						this.createToastForMobile(this.FormMSG(48, 'Success'), this.FormMSG(50, 'User substitut successfully!'));
						this.$emit('substitution:submitted', { responses });
					} else {
						this.loadingSubmit = false;
						this.createToastForMobile(this.FormMSG(51, 'Error'), this.FormMSG(52, 'Error replacement'), '', 'danger');
					}
				});
			} else {
				this.loadingSubmit = false;
			}
		},
		emitEventClose() {
			this.userReplacedSelected = null;
			this.$emit('substitution:close');
		}
	}
};
</script>

<style scoped></style>
