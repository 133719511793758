<template>
	<div class="v-bootstrap-tooltip-custom">
		<div v-if="IsRow" class="d-flex flex-column align-items-center">
			<template>
				<div v-if="mapDetail.length !== 0" style="width: 250px !important" v-for="(_item, index) of mapDetail" :key="index">
					<div
						v-for="(row, rowIndex) of mapTotalCostCenter(_item.totalByCostCenter)"
						:key="rowIndex"
						:style="{
							borderBottom: index === mapDetail.length - 1 ? '' : '1px solid #e4dfec'
						}"
					>
						<div
							class="d-flex flex-row align-items-center p-1"
							:style="{
								borderBottom: rowIndex === mapTotalCostCenter(_item.totalByCostCenter).length - 1 ? '' : '1px solid #e4dfec'
							}"
						>
							<div>
								<button class="btn-transparent text-color-rhapsody-in-blue" :id="`tooltip-mini-${rowIndex}-${index}`">
									<component :is="getLucideIcon(ICONS.INFO.name)" color="#FFFFFF" :size="16" :stroke-width="2.25" />
								</button>
							</div>
							<div class="d-flex flex-row align-items-center justify-content-start flex-grow-1" style="gap: 10px; width: 60%">
								<div
									:style="{
										width: '5px',
										height: '5px',
										backgroundColor: `#${row.color}`,
										borderRadius: '50%'
									}"
								></div>
								<div>{{ getTextDefault(row.costCenterActivity).text }}</div>
							</div>
							<div style="margin-left: 8px; height: 20px; border-left: 1px solid #e4dfec"></div>
							<div class="d-flex flex-row justify-content-end flex-grow-1 w-25">
								{{ rendAmountCurrency(row, false) }}
							</div>
						</div>
						<b-tooltip :target="`tooltip-mini-${rowIndex}-${index}`" placement="left" custom-class="v-bootstrap-tooltip-custom-content">
							<div class="v-bootstrap-tooltip-custom-mini">
								<div class="d-flex flex-column justify-content-start">
									<div class="d-flex flex-row align-items-center justify-content-start flex-grow-1" style="gap: 10px">
										<div
											:style="{
												width: '5px',
												height: '5px',
												marginLeft: '5px',
												backgroundColor: `#${row.color}`,
												borderRadius: '50%'
											}"
										></div>
										<div>{{ getTextDefault(getPhase(row)).text }}</div>
									</div>
									<div class="mini-content">{{ getTextDefault(getCostCenter(row)).text }}</div>
								</div>
							</div>
						</b-tooltip>
					</div>
				</div>
				<div v-if="mapDetail.length === 0">{{ FormMSG(1, 'None') }}</div>
			</template>
		</div>
		<div v-else class="d-flex flex-column align-items-center">
			<template>
				<div v-if="mapRowItem.length !== 0" style="width: 250px !important" v-for="(_item, index) of mapRowItem" :key="index">
					<div
						class="d-flex flex-row align-items-center p-1"
						:style="{
							borderBottom: index === mapRowItem.length - 1 ? '' : '1px solid #e4dfec'
						}"
					>
						<div>
							<button class="btn-transparent text-color-rhapsody-in-blue" :id="`tooltip-mini-${index}`">
								<component :is="getLucideIcon(ICONS.INFO.name)" color="#FFFFFF" :size="16" :stroke-width="2.25" />
							</button>
						</div>
						<div class="d-flex flex-row align-items-center justify-content-start flex-grow-1" style="gap: 10px; width: 60%">
							<div
								:style="{
									width: '5px',
									height: '5px',
									marginLeft: '5px',
									backgroundColor: `#${_item.color}`,
									borderRadius: '50%'
								}"
							></div>
							<div>{{ getTextDefault(_item.costCenterActivity).text }}</div>
						</div>
						<div style="margin-left: 8px; height: 20px; border-left: 1px solid #e4dfec"></div>
						<div class="d-flex flex-row justify-content-end flex-grow-1 w-25">
							{{ rendAmountCurrency(_item, true) }}
						</div>
					</div>
					<b-tooltip :target="`tooltip-mini-${index}`" placement="left" custom-class="v-bootstrap-tooltip-custom-content">
						<div class="v-bootstrap-tooltip-custom-mini">
							<div class="d-flex flex-column justify-content-start">
								<div class="d-flex flex-row align-items-center justify-content-start flex-grow-1" style="gap: 10px">
									<div
										:style="{
											width: '5px',
											height: '5px',
											marginLeft: '5px',
											backgroundColor: `#${_item.color}`,
											borderRadius: '50%'
										}"
									></div>
									<div>{{ getTextDefault(_item.projectPhase).text }}</div>
								</div>
								<div class="mini-content">{{ getTextDefault(_item.costCenter).text }}</div>
							</div>
						</div>
					</b-tooltip>
				</div>
				<div v-if="mapRowItem.length === 0">{{ FormMSG(1, 'None') }}</div>
			</template>
		</div>
	</div>
</template>
<script>
import { rendCurrency } from '~helpers';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { VALIDATION_TYPE } from '@/shared/constants';

export default {
	name: 'CustomTooltip',
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		IsRow: {
			type: Boolean,
			required: false,
			default: () => false
		},
		rowItem: {
			type: Array,
			required: false,
			default: () => []
		},
		item: {
			type: Object,
			required: false
		},
		type: {
			type: Number,
			required: false
		},
		isProduction: {
			type: Boolean,
			required: false
		}
	},
	watch: {},
	data: () => {
		return {
			rendCurrency,
			VALIDATION_TYPE
		};
	},
	computed: {
		mapDetail() {
			const tsDays = this.item.tsDaysTotal;
			if (tsDays && tsDays.length !== 0) {
				return tsDays.filter((item) => item.type === this.type);
			}
			return [];
		},
		mapRowItem() {
			const data = [...this.rowItem].filter((item) => item.type === this.type);
			if (data.length !== 0) {
				const response = [...data]
					.flatMap((item) => {
						const { hideSalary, totalByCostCenter } = item;
						const data = totalByCostCenter.map((_item) => ({ ..._item, hideSalary }));
						return data;
					})
					.filter(({ amount }) => amount !== 0);
				return response;
			}
			return [];
		}
	},
	methods: {
		mapTotalCostCenter(data) {
			if (data) {
				return data;
			}
			return [];
		},
		getTextDefault(text) {
			const isText = text.match(/[a-zA-Z0-9]/gi);
			if (isText && isText !== null) return { text, isValid: true };
			return { text: this.FormMSG(1, 'None'), isValid: false };
		},
		rendAmountCurrency(row, isEnd) {
			if (!isEnd) {
				const tsDays = this.item.tsDaysTotal || [];
				const uniques = [...tsDays].filter((item) => item.type === this.type);
				const hideSalaries = uniques.map(({ hideSalary }) => hideSalary).some((isHidden) => isHidden === true);
				if (row) {
					const { amount } = row;
					if (!hideSalaries) {
						return rendCurrency(amount, 3);
					} else {
						if (this.isProduction) {
							return rendCurrency(amount, 3);
						}
						const unite = `${rendCurrency(0, 3)}`.replaceAll('K', '').split(' ')[1];
						return `Xxx.xx ${unite}`;
					}
				} else {
					if (this.isProduction) {
						return rendCurrency(amount, 3);
					}
					const unite = `${rendCurrency(0, 3)}`.replaceAll('K', '').split(' ')[1];
					return `Xxx.xx ${unite}`;
				}
			} else {
				const { amount, hideSalary } = row;
				// if (!hideSalary) {
				// 	return rendCurrency(amount, 3);
				// } else {
				// 	if (this.isProduction) {
				// 		return rendCurrency(amount, 3);
				// 	}
				// 	const unite = `${rendCurrency(0, 3)}`.replaceAll('K', '').split(' ')[1];
				// 	return `Xxx.xx ${unite}`;
				// }
				return rendCurrency(amount, 3);
			}
		},
		getColor(item) {
			if (item) {
				const color = `#${item.projectFlagItem.color}`;
				return color;
			}
			return '';
		},
		getAmount(item) {
			if (item) {
				const amount = item.amount * item.quantity;
				return amount;
			}
			return 0;
		},
		getPhase(item) {
			if (item) {
				return item.projectPhase;
			}
			return this.FormMSG(1, 'None');
		},
		getCostCenter(item) {
			if (item) {
				return item.costCenter;
			}
			return this.FormMSG(1, 'None');
		},
		getConstCenterActivity(item) {
			if (item) {
				const short = item.activity.short;
				const phase = item.projectPhase.split('-')[0];
				const costCenter = item.costCenter.split('$')[0];
				const text = `${costCenter}${short}${phase}`;
				return text;
			}
			return this.FormMSG(1, 'None');
		}
	}
};
</script>
<style lang="scss">
$tooltipSecondaryColor: #112a39;
.tooltip-inner:has(.v-bootstrap-tooltip-custom) {
	max-width: 450px !important;
	padding: 0.25rem 0.5rem !important;
	color: #fff !important;
	text-align: center !important;
	background-color: #000 !important;
	border-radius: 0.25rem !important;
	margin-left: -200px !important;
}
.v-bootstrap-tooltip-custom-content {
	.arrow::before {
		border-left-color: $tooltipSecondaryColor !important;
	}
	.tooltip-inner {
		max-width: 450px !important;
		padding: 0.25rem 0.5rem !important;
		color: #fff !important;
		text-align: center !important;
		background-color: $tooltipSecondaryColor !important;
		border-radius: 0.25rem !important;
		margin-left: -400px !important;
	}
}
.mini-content {
	display: flex;
	justify-content: start;
	margin-left: 20px;
}
</style>
