var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.error, warning: _vm.warning } },
    [
      _c("custom-loading-planning", {
        attrs: {
          loading: _vm.isLoading,
          label: _vm.FormMSG(2, "Prepare data of fimalac"),
        },
      }),
      _c(
        "transition",
        { ref: "fimalac-planning-item", attrs: { name: "slide" } },
        [
          _vm.isMobile
            ? _c(
                "div",
                [
                  _c("b-alert", { attrs: { variant: "success", show: "" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.FormMSG(
                          151,
                          "You must use a computer to have a more information display of the planning"
                        )
                      )
                    ),
                  ]),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "form",
                  on: {
                    mousedown: _vm.handleMouseDown,
                    mouseup: _vm.handleMouseUp,
                  },
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "animated fadeIn" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", xl: "12" } },
                        [
                          _c("b-card", { attrs: { "no-body": "" } }, [
                            _c("div", { staticClass: "p-4" }, [
                              _c(
                                "div",
                                { attrs: { id: "header-context" } },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-alert",
                                            {
                                              attrs: {
                                                show: _vm.showTooltipsHelpPlanning,
                                                variant: "info",
                                                dismissible: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex mb-2" },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon("Info"),
                                                    { tag: "component" }
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ml-2 fw-700 fs-16",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            145,
                                                            "Planning tool"
                                                          )
                                                        ) + ":"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "ml-2" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          164,
                                                          "1- By default the tool shows all users of the project, but you can always filtered them by selecting a departement."
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "ml-2" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          165,
                                                          '2- To add a new planning(s) (i.e: To activate the button "Add to selection"):'
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                              _vm.isProduction
                                                ? _c(
                                                    "div",
                                                    { staticClass: "ml-3" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              166,
                                                              "- You must select one category, and one activity"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { staticClass: "ml-3" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          167,
                                                          "- You must check one or more PER DIEM » flag(s)"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "ml-3" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          168,
                                                          "- And the most important, selecting the planning of user(s) in the table (exactly as Excel fonctionnality's)"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            cols: _vm.isGestionnaryOfPaie
                                              ? 8
                                              : 10,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "pb-2 mr-1" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                  staticStyle: { gap: "10px" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row justify-content-start align-items-center gp-5",
                                                    },
                                                    [
                                                      _c("div", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.getStatus(
                                                              `status-not-submitted`,
                                                              _vm.initSatus
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-form-label",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                18,
                                                                "Not submitted"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row justify-content-start align-items-center gp-5",
                                                    },
                                                    [
                                                      _c("div", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.getStatus(
                                                              `status-info`,
                                                              _vm.initSatus
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-form-label",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                304,
                                                                "Transmitted Manager"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row justify-content-start align-items-center gp-5",
                                                    },
                                                    [
                                                      _c("div", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.getStatus(
                                                              `status-pending`,
                                                              _vm.initSatus
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-form-label",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                305,
                                                                "Validated Charged / Dir. by Prod."
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row justify-content-start align-items-center gp-5",
                                                    },
                                                    [
                                                      _c("div", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.getStatus(
                                                              `status-validated`,
                                                              _vm.initSatus
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-form-label",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                306,
                                                                "Validated Production Admin."
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row justify-content-start align-items-center gp-5",
                                                    },
                                                    [
                                                      _c("div", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.getStatus(
                                                              `status-refused`,
                                                              _vm.initSatus
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-form-label",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                16,
                                                                "Refused"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row justify-content-start align-items-center gp-5",
                                                    },
                                                    [
                                                      _c("div", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.getStatus(
                                                              `status-paie`,
                                                              _vm.initSatusPaie
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-form-label",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                303,
                                                                "Validated by the payroll manager"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.isProduction
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-row justify-content-start align-items-center gp-5",
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticClass:
                                                              "solve-status",
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-form-label",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    20,
                                                                    "Line to solved"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row justify-content-start align-items-center gp-5",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "exist-to-valid-ask",
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-form-label",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                22,
                                                                "Line indicating items to validate"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "d-flex flex-row align-items-center justify-content-end gp-20",
                                          attrs: {
                                            cols: _vm.isGestionnaryOfPaie
                                              ? 4
                                              : 2,
                                          },
                                        },
                                        [
                                          _vm.isGestionnaryOfPaie
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "float-left bg-tertiaire",
                                                  attrs: {
                                                    disabled: _vm.canSendToPay,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.handleOpenModalPayroll,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon("Euro"),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        size: 16,
                                                        "stroke-width": 3,
                                                      },
                                                    }
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            258,
                                                            "Send to payroll"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _vm.isAlreadySendEdToPayroll
                                                    ? _c(
                                                        "div",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "b-tooltip",
                                                              rawName:
                                                                "v-b-tooltip.hover.top:title",
                                                              value:
                                                                _vm.FormMSG(
                                                                  259,
                                                                  "The planning has already been sent to payroll"
                                                                ),
                                                              expression:
                                                                "FormMSG(259, 'The planning has already been sent to payroll')",
                                                              modifiers: {
                                                                hover: true,
                                                                "top:title": true,
                                                              },
                                                            },
                                                          ],
                                                          staticClass:
                                                            "icon-container",
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "checkmark",
                                                              attrs: {
                                                                xmlns:
                                                                  "http://www.w3.org/2000/svg",
                                                                viewBox:
                                                                  "0 0 52 52",
                                                              },
                                                            },
                                                            [
                                                              _c("circle", {
                                                                staticClass:
                                                                  "checkmark-circle",
                                                                attrs: {
                                                                  stroke:
                                                                    "#5cb85c",
                                                                  "stroke-width":
                                                                    "8",
                                                                  cx: "26",
                                                                  cy: "26",
                                                                  r: "25",
                                                                  fill: "#ffffff",
                                                                },
                                                              }),
                                                              _c("path", {
                                                                staticClass:
                                                                  "checkmark-check",
                                                                attrs: {
                                                                  fill: "none",
                                                                  stroke:
                                                                    "#5cb85c",
                                                                  "stroke-width":
                                                                    "8",
                                                                  d: "M14 27l8 8 16-16",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isProduction
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass: "float-right",
                                                  attrs: { variant: "success" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showManageUsersRolesDialog = true
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "UserCog"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: { size: 20 },
                                                    }
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            417,
                                                            "Manage user's roles"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("header-planning-fimalac", {
                                        attrs: {
                                          id: _vm.timecards_One,
                                          onSearch: _vm.onSearch,
                                        },
                                        on: {
                                          handleSelectDepartementChange:
                                            _vm.handleSelectDepartementChange,
                                          handleSelectFunctionChange:
                                            _vm.handleSelectFunctionChange,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("HeaderFlag", {
                                        ref: "headerFlag",
                                        attrs: {
                                          dataPlanningSelect:
                                            _vm.dataPlanningSelect,
                                          DateDefaultSelect:
                                            _vm.DateDefaultSelect,
                                          favoriteProjectFlagItems:
                                            _vm.flagFavoritesBookmark,
                                          isNotFavoriteProjectFlagItems:
                                            _vm.flagFavoritesNotBookmark,
                                          currentValidationLevel:
                                            _vm.currentValidationLevel,
                                          fromComponent: _vm.fromComponent,
                                          activities: _vm.activities,
                                          categories: _vm.categories,
                                          dataGlobal: _vm.TimeCards,
                                          trimestre: _vm.getTrimestre,
                                          isGestionnaryOfPaie:
                                            _vm.isGestionnaryOfPaie,
                                          statusPlanningValidateAll:
                                            _vm.statusPlanningValidateAll,
                                          manager: _vm.manager,
                                          userValidationLevel:
                                            _vm.userValidationLevel,
                                          planningLevel: _vm.planningLevel,
                                          finalizationDate:
                                            _vm.finalizationDate,
                                          lockPlanning: _vm.lockPlanning,
                                          planningOption: _vm.planningOption,
                                          previousPlanningStatus:
                                            _vm.previousPlanningStatus,
                                        },
                                        on: {
                                          changeDateDefaultSelect:
                                            _vm.changeDateDefaultSelect,
                                          "on-add-data:selection":
                                            _vm.handleOnAddDataSelection,
                                          "on-preference:open":
                                            _vm.handleOpenPrefrence,
                                          "refersh-on-delete-not-submitted-all":
                                            _vm.handleRefreshData,
                                          "close-planning-modal-delete-all":
                                            _vm.hideModal,
                                          "on-active-is-loading":
                                            _vm.onActiveIsLoading,
                                          "on-show-is-loading":
                                            _vm.onShowLoading,
                                          "on-close-is-loading":
                                            _vm.onCloseLoading,
                                          "init-persist-planning":
                                            _vm.handleInitPlanningSelect,
                                          "on-refresh-cell-planning-select":
                                            _vm.handleInitCellPlanningSelect,
                                          validateAllPlanning:
                                            _vm.validateAllPlanning,
                                          "handle-change-affichage":
                                            _vm.onHandleChangeAffichage,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", {
                                    staticClass: "divider-horizontal",
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "planning" },
                                [
                                  _c("planning-view-tables", {
                                    ref: "planning-view-table",
                                    attrs: {
                                      id: _vm.timecards_One,
                                      selectDate: _vm.allDataTimesheets,
                                      hover: true,
                                      currentpage: _vm.currentPage,
                                      perpage: _vm.perPage,
                                      hv: _vm.hv,
                                      tsfields: _vm.mapFieldsTimeCardsPlanning,
                                      sizeCell: _vm.sizeCell,
                                      timesheets: _vm.TimeCards,
                                      DateDefaultSelect: _vm.DateDefaultSelect,
                                      customFilterValue: _vm.customFilterValue,
                                      isPersistDataPlanning:
                                        _vm.isPersistDataPlanning,
                                      initCustomFilter: _vm.initCustomFilter,
                                      hideContextMenuPlanning:
                                        _vm.hideContextMenuPlanning,
                                      initPlanningSelect:
                                        _vm.initPlanningSelect,
                                      isAnalyTicalCode: _vm.isAnalyTicalCode,
                                      isPrestations: _vm.isPrestations,
                                      isPerDiem: _vm.isPerDiem,
                                      totalOfUsers: _vm.totalOfUsers,
                                      totalGrouping: _vm.totalGrouping,
                                      isGestionnaryOfPaie:
                                        _vm.isGestionnaryOfPaie,
                                      statusPlanningValidateAll:
                                        _vm.statusPlanningValidateAll,
                                      isActiveBusy: _vm.isActiveBusy,
                                      locationDatesSize: _vm.locationDatesSize,
                                      stopInfiniteScroll:
                                        _vm.stopInfiniteScroll,
                                      lockPlanning: _vm.lockPlanning,
                                      isManagerPresent: _vm.isManagerPresent,
                                      planningOption: _vm.planningOption,
                                      previousPlanningStatus:
                                        _vm.previousPlanningStatus,
                                    },
                                    on: {
                                      setDataSizeModalPlanning:
                                        _vm.setDataSizeModalPlanning,
                                      showModalPlanningContextUpdate:
                                        _vm.showModalPlanningContextUpdate,
                                      changeDateDefaultSelect:
                                        _vm.changeDateDefaultSelect,
                                      getDataFromPagePlanning:
                                        _vm.getDataFromPagePlanning,
                                      validateTsDays: _vm.validateTsDays,
                                      "row-validate-change":
                                        _vm.rowValidateChange,
                                      deleteItemPlanning:
                                        _vm.deleteItemPlanning,
                                      replacement: _vm.replacement,
                                      handleRefreshReject:
                                        _vm.handleRefreshReject,
                                      "filter-custom-user":
                                        _vm.handleCustomFilter,
                                      "on-open-modal-new-user":
                                        _vm.handleAddNewUser,
                                      "reset-init-custom-filter":
                                        _vm.resetInitCustomFilter,
                                      hideContextMenuPlanningInit:
                                        _vm.handleHideContextMenuPlanning,
                                      "restore-init-planning-select":
                                        _vm.handleRestoredinitPlanningSelect,
                                      setCurrentValidationLevel:
                                        _vm.setCurrentValidationLevel,
                                      "planning-view-tables:edit-location":
                                        _vm.handlePlanningViewTablesEditLocation,
                                      showModalDetailsCell:
                                        _vm.showModalDetailsCell,
                                      showModalDetailsCellMultiple:
                                        _vm.showModalDetailsCellMultiple,
                                      onOpenRejectModal: _vm.onOpenRejectModal,
                                      "refersh-on-planning":
                                        _vm.handleRefreshData,
                                      "hide-column-header":
                                        _vm.handleHideHeaderColumn,
                                      handleRefresHeader:
                                        _vm.handleRefresHeader,
                                      "on-show-is-loading": _vm.onShowLoading,
                                      "quick-change-load:submitted":
                                        _vm.handleChangeCell,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-row w-100 gap-1 p-4",
                                },
                                [
                                  _c("quick-addition", {
                                    ref: "quick-addition",
                                    attrs: {
                                      open: _vm.showQuickAdditionModal,
                                      "external-data": _vm.dataToAdd,
                                      "is-auth": _vm.isProduction,
                                      locationList: _vm.locationList,
                                      activeLocation: _vm.activeLocation,
                                      headerTabPlanning: _vm.headerTabPlanning,
                                      dataPlanningSelect:
                                        _vm.dataPlanningSelect,
                                    },
                                    on: {
                                      "quick-addition:close":
                                        _vm.handleQuickAdditionClose,
                                      "quick-change-load:submitted":
                                        _vm.handleChangeCell,
                                      "on-active-is-loading":
                                        _vm.onActiveIsLoading,
                                    },
                                  }),
                                  _c("substitution", {
                                    attrs: {
                                      open: _vm.showSubstitutionModal,
                                      "external-data": _vm.editData,
                                      customFilterValue: _vm.customFilterValue,
                                    },
                                    on: {
                                      "substitution:close":
                                        _vm.handleSubstitutionClose,
                                      "substitution:submitted":
                                        _vm.handleSubstitutionSubmitted,
                                    },
                                  }),
                                  _c("user-advanced-edit", {
                                    attrs: {
                                      open: _vm.showUserAdvancedEditModal,
                                      "external-data": _vm.editData,
                                      "is-regissor": _vm.isProduction === false,
                                      locationList: _vm.locationList,
                                      trimestre: _vm.getTrimestre,
                                      currentValidationLevel:
                                        _vm.currentValidationLevel,
                                      isGestionnaryOfPaie:
                                        _vm.isGestionnaryOfPaie,
                                      editDataMultiple: _vm.editDataMultiple,
                                      isMultipleEdit: _vm.isMultipleEdit,
                                    },
                                    on: {
                                      "user-advanced-edit:close":
                                        _vm.handleUserAdvancedEditClose,
                                      "on-active-is-loading":
                                        _vm.onActiveIsLoading,
                                      userDeleteUpdateAdvancedEdit:
                                        _vm.userDeleteUpdateAdvancedEdit,
                                    },
                                  }),
                                  _c("preferences", {
                                    attrs: {
                                      open: _vm.showPreferencesModal,
                                      "refresh-modal":
                                        _vm.refreshModalPreferences,
                                      isProduction: _vm.isProduction,
                                    },
                                    on: {
                                      "preferences:close":
                                        _vm.handlePreferencesClose,
                                      "preferences:refresh-end":
                                        _vm.handlePreferencesRefreshEnd,
                                      "is-success-to-refresh":
                                        _vm.handleRefreshData,
                                      "on-show-is-loading": _vm.onShowLoading,
                                    },
                                  }),
                                  _c("custom-filter", {
                                    attrs: {
                                      open: _vm.openCustomFilter,
                                      "refresh-modal":
                                        _vm.refreshModalCustomFilter,
                                    },
                                    on: {
                                      "custom-filter:success":
                                        _vm.handleRefreshCustomFilter,
                                      "custom-filter:close":
                                        _vm.handleCustomFilterClose,
                                      "custom-filter:refresh-end":
                                        _vm.handleCustomFilterRefreshEnd,
                                    },
                                  }),
                                  _c("invite-new-user", {
                                    attrs: { open: _vm.openModalNewUser },
                                    on: {
                                      "invite-new-user:users-added":
                                        _vm.handleInviteNewUserUsersAdded,
                                      "invite-new-user:close":
                                        _vm.handleInviteNewUserClose,
                                      "init-total:submitted": _vm.getTotalUsers,
                                      "on-active-is-loading":
                                        _vm.onActiveIsLoading,
                                    },
                                  }),
                                  _c("manage-users-roles", {
                                    attrs: {
                                      open: _vm.showManageUsersRolesDialog,
                                      "refresh-modal":
                                        _vm.refreshModalManageUsers,
                                    },
                                    on: {
                                      "manage-users-roles:close":
                                        _vm.handleManageUsersRolesClose,
                                      "manage-users-roles:refresh-end":
                                        _vm.handleManageUsersRolesRefreshEnd,
                                      "set-exist-regissor:event":
                                        _vm.handleActiveRegissor,
                                    },
                                  }),
                                  _c("incomplete", {
                                    attrs: {
                                      open: _vm.successModalIncomplete,
                                      data: _vm.incompleteData,
                                      date: _vm.DateDefaultSelect,
                                    },
                                    on: {
                                      "modal-incomplete:close":
                                        _vm.handleIncompleted,
                                    },
                                  }),
                                  _c("edit-location", {
                                    attrs: {
                                      open: _vm.showModalEditLocation,
                                      "data-for-edit": _vm.dataForEditLocation,
                                    },
                                    on: {
                                      "edit-location:close":
                                        _vm.handleEditLocationClose,
                                      "edit-location:add-success":
                                        _vm.handleEditLocationAddSuccess,
                                    },
                                  }),
                                  _c("payroll-config", {
                                    attrs: {
                                      open: _vm.modalPayrollOpen,
                                      dateDefaultSelect: _vm.DateDefaultSelect,
                                      planningOption: _vm.planningOption,
                                      statusPlanningValidateAll:
                                        _vm.statusPlanningValidateAll,
                                    },
                                    on: {
                                      actionModal: _vm.handleOpenModalPayroll,
                                      "is-success-to-refresh":
                                        _vm.handleRefreshData,
                                    },
                                  }),
                                  _c(
                                    "b-modal",
                                    {
                                      staticClass: "modal-success",
                                      attrs: {
                                        "header-class":
                                          "header-class-modal-doc-package",
                                        title: _vm.FormMSG(160, "Informations"),
                                        size: "xxl",
                                        "no-close-on-backdrop": "",
                                        "ok-variant": "success",
                                        "ok-only": "",
                                        "modal-class": "mui-animation",
                                        fade: false,
                                      },
                                      on: { ok: _vm.hideModalDetailsInfo },
                                      model: {
                                        value: _vm.showDetailModalCell,
                                        callback: function ($$v) {
                                          _vm.showDetailModalCell = $$v
                                        },
                                        expression: "showDetailModalCell",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "container-mobile animated fadeIn",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-detailsMyTimesheet-mobile",
                                            },
                                            [
                                              _c(
                                                "show-detail-planning-cell-fimalac",
                                                {
                                                  attrs: {
                                                    showDetailData:
                                                      _vm.showDetailData,
                                                    isMultiple: false,
                                                    isProduction:
                                                      _vm.isProduction,
                                                    isGestionnaryOfPaie:
                                                      _vm.isGestionnaryOfPaie,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-modal",
                                    {
                                      staticClass: "modal-success",
                                      attrs: {
                                        "header-class":
                                          "header-class-modal-doc-package",
                                        title: _vm.FormMSG(160, "Informations"),
                                        size: "xxl",
                                        "no-close-on-backdrop": "",
                                        "ok-variant": "success",
                                        "ok-only": "",
                                        "modal-class": "mui-animation",
                                        fade: false,
                                      },
                                      on: {
                                        ok: _vm.hideModalDetailsInfoMultiple,
                                      },
                                      model: {
                                        value: _vm.showDetailModalCellMultiple,
                                        callback: function ($$v) {
                                          _vm.showDetailModalCellMultiple = $$v
                                        },
                                        expression:
                                          "showDetailModalCellMultiple",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "container-mobile animated fadeIn",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-detailsMyTimesheet-mobile",
                                            },
                                            [
                                              _c(
                                                "show-detail-planning-cell-fimalac",
                                                {
                                                  attrs: {
                                                    showDetailDataMultiple:
                                                      _vm.showDetailDataMultiple,
                                                    isMultiple: true,
                                                    isProduction:
                                                      _vm.isProduction,
                                                    isGestionnaryOfPaie:
                                                      _vm.isGestionnaryOfPaie,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-modal",
                                    {
                                      staticClass: "modal-danger",
                                      attrs: {
                                        "header-class":
                                          "header-class-modal-doc-package",
                                        title: _vm.FormMSG(
                                          170,
                                          "Refuse this day"
                                        ),
                                        "no-close-on-backdrop": "",
                                        "ok-variant": "danger",
                                        "ok-only": "",
                                        "modal-class": "mui-animation",
                                        fade: false,
                                      },
                                      on: { hidden: _vm.onCloseRejectModal },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "modal-footer",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "w-100" },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "float-right",
                                                      attrs: {
                                                        disabled:
                                                          _vm.disableReject,
                                                        variant: "danger",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.finaliseTsDayReject,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tOK\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                      model: {
                                        value: _vm.successModalRejectedTsDay,
                                        callback: function ($$v) {
                                          _vm.successModalRejectedTsDay = $$v
                                        },
                                        expression: "successModalRejectedTsDay",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              171,
                                              "Enter your comment:"
                                            ),
                                            "label-for": "comment",
                                            "label-cols": 4,
                                          },
                                        },
                                        [
                                          _c("b-form-textarea", {
                                            attrs: {
                                              id: "comment",
                                              placeholder: this.FormMSG(
                                                172,
                                                "Enter your remarks..."
                                              ),
                                              rows: "3",
                                            },
                                            on: {
                                              input: _vm.handleChangeComment,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }